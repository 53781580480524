import { useConfigStore } from '~/stores/config';
import { Context, Middleware } from '@nuxt/types';

const middleware: Middleware = ({ error }: Context) => {
  const { storeConfig } = useConfigStore();
  const isStoreCreditEnabled = storeConfig.customer_magento_customerbalance_is_enabled === '1';

  if (!isStoreCreditEnabled) error({ statusCode: 404 });
};

export default middleware;
