const middleware = {}

middleware['check-store-credit'] = require('../middleware/check-store-credit.ts')
middleware['check-store-credit'] = middleware['check-store-credit'].default || middleware['check-store-credit']

middleware['check-wishlist'] = require('../middleware/check-wishlist.ts')
middleware['check-wishlist'] = middleware['check-wishlist'].default || middleware['check-wishlist']

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-existing-category'] = require('../middleware/is-existing-category.ts')
middleware['is-existing-category'] = middleware['is-existing-category'].default || middleware['is-existing-category']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
