import jwt from 'jsonwebtoken';
import { useContext } from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { Logger } from '~/helpers/logger';
import {
  ILogAdminAction,
  LogAdminActionInput,
  LogAdminActionOutput
} from './useLogAdminAction';
import logAdminActionGql from './logAdminActions.gql';

export function useLogAdminAction(): ILogAdminAction {
  const { mutate } = useApi();
  const { app } = useContext();

  const isAdmin = (): boolean => {
    const token = jwt.decode(
      app.context.$cookies.get('vsf-customer') || ''
    );

    return !!token?.['admin-id'];
  };

  const convertData = (data: any): string => {
    return new URLSearchParams(data).toString();
  }

  const submitAdminAction = async (input: LogAdminActionInput): Promise<void> => {
    if (!isAdmin()) {
      return;
    }

    Logger.debug('useLogAdminAction/submit', input);

    try {
      if (input.new_info && input.old_info) {
        input.new_info = convertData(input.new_info);
        input.old_info = convertData(input.old_info);
      }

      await mutate<LogAdminActionOutput>(logAdminActionGql, input);

    } catch (err) {
      Logger.error('useLogAdminAction/submit', err);
    }
  };

  return {
    submitAdminAction
  };
}

export default useLogAdminAction;
