import type {
  CartItemInput,
  ConfigurableProduct,
  GroupProductVariant,
  Cart,
  ProductInterface,
} from '~/modules/GraphQL/types';
import {
  BBRProductAttributes,
  canHaveCaseOrderUnit,
  getAttributeValue,
  getSellByCaseOnly,
  getStockDataQty,
  getListingId,
  getListingType,
  isBbxVariantType,
  getVariantSelectedOptions,
  isSameProduct,
} from '~/bbrTheme/modules/catalog/getters/productGetters';
import { CartItemInterface } from '~/modules/GraphQL/types';

export interface ListingParams {
  listing_id?: string;
  listing_type?: string;
}

export interface AddToCartGetters {
  getAddedQtyToCartBySku(cart: Cart | null, product: ProductInterface),
  getVariantMaxQty(cart: Cart | null, product: ProductInterface, isCase: boolean),
  getConfigurableCartItemsInput(
    currentCart: Cart,
    product: ConfigurableProduct,
    variants: GroupProductVariant[],
    quantity: number,
    params: object,
  ): CartItemInput[];
}

export const getListingParams = (product: ProductInterface): ListingParams => {
  const params: ListingParams = {};

  if (isBbxVariantType(product)) {
    params.listing_id = getListingId(product).toString();
    params.listing_type = getListingType(product);
  }

  return params;
};

export const getAddedQtyToCartBySku = (cart: Cart | null, product: ProductInterface): number => {
  let qty = 0;
  const items = cart?.items?.filter((item: CartItemInterface) => isSameProduct(item.configured_variant, product));

  if (items) {
    items.forEach((item: CartItemInterface) => {
      qty += item.quantity;
    });
  }

  return qty;
};

export const getVariantMaxQty = (cart: Cart | null, product: ProductInterface, isCase = false): number => {
  const maxQty = getStockDataQty(product) - getAddedQtyToCartBySku(cart, product);

  if (canHaveCaseOrderUnit(product) && isCase) {
    const caseOrderUnit = Number(getAttributeValue(product, BBRProductAttributes.CASE_ORDER_UNIT));

    return maxQty && caseOrderUnit ? Math.floor(maxQty / caseOrderUnit) : maxQty;
  }

  return maxQty;
};

export const getConfigurableCartItemsInput = (
  currentCart: Cart,
  product: ConfigurableProduct,
  variants: GroupProductVariant[],
  quantity: number,
  params = {},
): CartItemInput[] => {
  let remainingQty = quantity;
  const cartItems = [] as CartItemInput[];

  for (let i = 0; i < variants.length; i++) {
    const variant = variants[i];
    const addedQty = getAddedQtyToCartBySku(currentCart, variant.product);
    const variantQty = (getStockDataQty(variant.product) - addedQty);

    if (variantQty <= 0) {
      continue;
    }

    cartItems.push({
      sku: product.sku,
      selected_options: getVariantSelectedOptions(variant),
      quantity: Math.min(remainingQty, variantQty),
      ...getListingParams(variant.product),
      ...params,
    });

    remainingQty -= variantQty;

    if (remainingQty <= 0) {
      break;
    }
  }

  return cartItems;
};

const addToCartGetters: AddToCartGetters = {
  getAddedQtyToCartBySku,
  getVariantMaxQty,
  getConfigurableCartItemsInput,
};

export default addToCartGetters;
