import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type {
  UseUserOrderErrors,
  UseUserOrderInterface,
  UseUserOrderSearchParams,
} from './useUserOrder';
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseUserOrderErrors>({
    search: null,
  });

  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { params });

      if (isDualRunning(app.$cookies)) {
        if (params?.filter?.number?.eq) {
          const orderResponse = await app.context.$vsf.$hybris.api.getOrderDetails({
            orderCode: params.filter.number.eq,
          });

          results = orderResponse?.data?.orderData;
        } else {
          // Hybris pagination starts at 0
          params.currentPage = params.currentPage ? Math.max(0, params.currentPage - 1) : 0;
          const ordersResponse = await app.context.$vsf.$hybris.api.getOrders(params);
          const paymentsResponse = await app.context.$vsf.$hybris.api.getPayments(params);

          results = {
            orders: ordersResponse?.data?.orders,
            payments: paymentsResponse?.data?.payments,
          };
        }

        Logger.debug('[Result]:', { results });
      } else {
        // eslint-disable-next-line max-len
        const { data } = await app.$vsf.$magento.api.customerOrders(
          params,
          params?.customQuery ?? null,
          params?.customHeaders
        );

        results = {
          orders: data?.customer?.orders ?? [],
        };

        Logger.debug('[Result]:', { data });
      }

      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUserOrder;
export * from './useUserOrder';
