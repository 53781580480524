import { useCellarStore } from '@cellar-services/stores/cellar';
import {
  useBBXAccountStore,
  useListingsStore,
} from '@cellar-services/stores/bbx';
import { storeToRefs } from 'pinia';
import { useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { CprStockInterface } from '@cellar-services/types';

/**
 * Cellar actions
 */
export const useCellar = () => {
  const context = useContext();
  const cellarStore = useCellarStore();
  const listingsStore = useListingsStore();
  const bbxAccountStore = useBBXAccountStore();

  const { defaultID, stats } = storeToRefs(cellarStore);
  const { setStats } = cellarStore;

  const getCellarSummary = async (hardRefresh = false): Promise<void> => {
    if (stats.value?.total_summary && !hardRefresh) {
      return;
    }

    if (defaultID.value) {
      const result = await context.$vsf.$cellarServices.api.getCellarSummary({
        account_id: defaultID.value,
      });

      if (result?.status >= 300) {
        Logger.error(result?.message || '');
      } else {
        setStats(result);
      }
    }
  };

  const resetCellar = () => {
    cellarStore.resetStore();
    listingsStore.resetStore();
    bbxAccountStore.resetStore();

    setStats(null);
  };

  const refreshCellar = async (): Promise<any> => {
    Logger.info('Refreshing cellar...');

    getCellarSummary(true);
  };

  return {
    refreshCellar,
    getCellarSummary,
    resetCellar,
  };
};
