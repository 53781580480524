import { defineStore } from 'pinia';
import {
  ref,
  computed,
} from '@nuxtjs/composition-api';

export interface BidAlertThresholdTypeInterface {
  type: string;
  label: string;
  isSelected: boolean;
}

export interface BidAlertSavePayloadInterface {
  thresholdType: string;
  thresoldValue: number;
  realTimeAlert: boolean;
}

export interface BidAlertResponseInterface {
  bbxAlertThresholdTypeData: null | {
    name: string;
    code: string;
  };
  thresholdAmount: null | number;
}

export const BID_ALERTS_THRESHOLD_TYPES: BidAlertThresholdTypeInterface[] = [{
  type: 'livExMarketPricePercentage',
  label: 'Percentage below Liv-ex market price',
  isSelected: true,
}, {
  type: 'lowestPurchasePricePercentage',
  label: 'Percentage above lowest purchase price',
  isSelected: false,
}, {
  type: 'noThreshold',
  label: 'No threshold - receive all bids',
  isSelected: false,
}];

export const DEFAULT_BID_ALERTS_THRESHOLD = 15;

export const useBBXAccountStore = defineStore('bbx-account', () => {
  // State
  // Settings for bid alerts for the entire cellar
  const bidAlertsEnabled = ref(false);
  const bidAlertsThreshold = ref(DEFAULT_BID_ALERTS_THRESHOLD);
  const bidAlertsThresholdTypes = ref(BID_ALERTS_THRESHOLD_TYPES);

  // Getters
  const selectedBidAlertsThresholdType = computed(() => {
    const bidAlertsThresholdType = bidAlertsThresholdTypes.value.find((option) => option.isSelected);

    if (!bidAlertsThresholdType) {
      return BID_ALERTS_THRESHOLD_TYPES[0];
    }

    return bidAlertsThresholdType;
  });

  const isBidAlertsThresholdAvailable = computed(() => {
    return selectedBidAlertsThresholdType.value.type !== 'noThreshold';
  });

  // Actions
  const setBidAlertsEnabled = (value: boolean) => {
    bidAlertsEnabled.value = value;
  };

  const setBidAlertsThreshold = (value: number | null) => {
    bidAlertsThreshold.value = value || DEFAULT_BID_ALERTS_THRESHOLD;
  };

  const setBidAlertsThresholdType = (value) => {
    bidAlertsThresholdTypes.value.forEach((option) => {
      option.isSelected = option.type === value;
    });
  };

  const resetStore = () => {
    setBidAlertsEnabled(true);
    setBidAlertsThreshold(DEFAULT_BID_ALERTS_THRESHOLD);

    bidAlertsThresholdTypes.value = BID_ALERTS_THRESHOLD_TYPES;
  };

  return {
    DEFAULT_BID_ALERTS_THRESHOLD,
    bidAlertsEnabled,
    setBidAlertsEnabled,
    bidAlertsThreshold,
    setBidAlertsThreshold,
    selectedBidAlertsThresholdType,
    bidAlertsThresholdTypes,
    setBidAlertsThresholdType,
    isBidAlertsThresholdAvailable,
    resetStore,
  };
});
