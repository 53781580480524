import { extractImage } from '@vsf-enterprise/amplience';
import type { MappedImage, JsonLdSchema, JsonLdSchemaContactPoint } from '@amplience/types';
import type {
  WithContext,
  Organization,
  WebSite,
  ListItem,
  ItemList,
  Product as SchemaProduct,
  ProductGroup,
} from 'schema-dts';
import type { Breadcrumb } from '~/modules/catalog/types';
import type { Product } from '~/modules/catalog/product/types';
import { ConfigurableVariant, ProductCustomAttributes, ProductReview } from '~/modules/GraphQL/types';
import { BBRProductAttributes, getAttributeValue } from '@theme/modules/catalog/getters/productGetters';
import {
  getConvertedCriticReviewDateToISO,
  getProductUrl,
  getProductCustomPrices,
  getStockStatus,
  getCheapestAndMostExpensiveVariants,
} from '~/modules/catalog/product/getters/productGetters';
import { averageNormalizedRatings, normalizeRating } from '~/modules/review/helpers/reviewHelper';

export const getJsonLdOrganization = (schema: JsonLdSchema): WithContext<Organization> => {
  const image: MappedImage = extractImage(schema.logo);

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: schema.name,
    url: schema.url,
    logo: image.url,
    contactPoint: schema.contactPoint.map((point: JsonLdSchemaContactPoint) => ({
      '@type': 'ContactPoint',
      telephone: point.telephone,
      contactType: point.type,
      areaServed: point.areaServed,
    })),
    sameAs: schema.sameAs,
  };
};

export const getJsonLdWebSite = (schema: JsonLdSchema): WithContext<WebSite> => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: schema.name,
  url: schema.url,
  potentialAction: {
    // @ts-ignore
    '@type': 'SearchAction',
    'target': `${schema.url}search?query={query}`,
    'query': 'required',
    // @ts-ignore
    'query-input': 'required name=query',
  },
});

export const getJsonLdBreadcrumbs = (
  breadcrumbs: Breadcrumb[],
  currentRoute: string,
  baseURL: string,
): WithContext<ItemList> => {
  const items = breadcrumbs.map((item: Breadcrumb, index: number): ListItem => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': `${!item.link || item.link.startsWith('/') ? baseURL : ''}${item.link || currentRoute}`,
      name: item.text,
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items,
  };
};

export const getJsonLdProduct = (
  product: Product,
  baseURL: string,
  organization: string,
  image: string,
): WithContext<SchemaProduct> => {
  const url = `${baseURL}${getProductUrl(product)}`;
  const property = getAttributeValue(product, 'property') || organization;
  const customPrices = getProductCustomPrices(product);
  const reviews = (product.reviews?.items || []).map((review: ProductReview) => ({
    ...review,
    score: normalizeRating(review.score),
  })).filter((review: ProductReview) => review.score);
  const averageRating = averageNormalizedRatings(reviews.map((review: ProductReview) => Number(review.score)));

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.name,
    description: (
      product.meta_description || product.description?.html || product.short_description?.html || ''
    ).replace(/<[^>]*>/g, ''),
    image,
    sku: product.sku,
    mpn: product.sku,
    brand: {
      '@type': 'Brand',
      name: property,
    },
    url,
    offers: {
      '@type': 'Offer',
      priceCurrency: customPrices.price_value.amount.currency,
      price: customPrices.price_value.amount.value,
      itemCondition: 'https://schema.org/NewCondition',
      availability: getStockStatus(product) ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      url,
      seller: {
        '@type': 'Organization',
        name: organization,
      },
    },
    aggregateRating: reviews.length > 0 ? {
      '@type': 'AggregateRating',
      ratingValue: averageRating,
      reviewCount: reviews.length,
      ratingCount: reviews.length,
    } : null,
    review: reviews.length > 0 ? reviews.map((review: ProductReview) => ({
      '@type': 'CriticReview',
      author: {
        '@type': 'Person',
        name: review.nickname,
        affiliation: {
          '@type': 'Organization',
          name: review.summary,
        }
      },
      datePublished: getConvertedCriticReviewDateToISO(review.review_date || ''),
      reviewBody: review.text,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.score,
        bestRating: '5',
      },
    })) : null,
  };
};

export const getJsonLdProductGroup = (
  product: Product,
  baseURL: string,
  organization: string,
  image: string,
): WithContext<ProductGroup> => {
  const productSchema = getJsonLdProduct(product, baseURL, organization, image);
  const variants = product.variants || [];
  const sortedVariants = getCheapestAndMostExpensiveVariants(variants);
  const lowestPrices = getProductCustomPrices(sortedVariants.cheapest?.product);
  const highestPrices = getProductCustomPrices(sortedVariants.expensive?.product);

  return {
    ...productSchema,
    '@type': 'ProductGroup',
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: lowestPrices.price_value?.amount.value,
      highPrice: highestPrices.price_value?.amount.value,
      priceCurrency: lowestPrices.price_value?.amount.currency,
      offerCount: variants.length
    },
    hasVariant: variants.map((variant: ConfigurableVariant): SchemaProduct => {
      const customPrices = getProductCustomPrices(variant.product);
      const attributes = (variant.product.attributes_value || []).filter((attribute: ProductCustomAttributes) => {
        return [
          BBRProductAttributes.ALCOHOL,
          BBRProductAttributes.BODY,
          BBRProductAttributes.COLOUR,
          BBRProductAttributes.EVENT_TYPE,
          BBRProductAttributes.EVENT_START_TIME,
          BBRProductAttributes.EVENT_END_TIME,
          BBRProductAttributes.EVENT_START_DATE,
          BBRProductAttributes.EVENT_LOCATION,
          BBRProductAttributes.EVENT_MAX_TICKETS,
          BBRProductAttributes.EVENT_DRESS_CODE,
          BBRProductAttributes.EVENT_HOSTS,
          BBRProductAttributes.MATURITY,
          BBRProductAttributes.PROPERTY,
          BBRProductAttributes.REGION,
          BBRProductAttributes.STYLE_TEMP,
          BBRProductAttributes.SWEETNESS,
          BBRProductAttributes.VINTAGE,
          BBRProductAttributes.BOTTLE_ORDER_UNIT,
          BBRProductAttributes.BOTTLE_VOLUME,
          BBRProductAttributes.CASE_ORDER_UNIT,
          BBRProductAttributes.DUTIABLE_VOLUME_ML,
        ].includes(attribute.code as BBRProductAttributes);
      });

      return {
        ...productSchema,
        '@type': 'Product',
        name: variant.product.name,
        sku: variant.product.sku,
        description: productSchema.description,
        image,
        offers: {
          '@type': 'Offer',
          priceCurrency: customPrices.price_value.amount.currency,
          price: customPrices.price_value.amount.value,
          availability: 'https://schema.org/InStock',
        },
        additionalProperty: attributes.map((attribute: ProductCustomAttributes) => ({
          '@type': 'PropertyValue',
          name: attribute.label,
          value: attribute.value,
        }))
      };
    }),
  };
};
