export const normalizeRating = (rating: string): null | string => {
  if (!rating) return null;

  let str = rating.trim();
  let parts = str.split('/');

  let denominator = '5';
  let currentRating = '5';

  if (parts.length === 1) {
    currentRating = parts[0];
  } else if (parts.length === 2) {
    currentRating = parts[0];
    denominator = parts[1];
  } else {
    denominator = parts.pop();
    currentRating = parts.join(' ');
  }

  denominator = denominator.replace(/[^\d\.]+/g, '');

  let denominatorValue = parseFloat(denominator);

  if (isNaN(denominatorValue) || denominatorValue === 0) {
    denominatorValue = 5;
  }

  currentRating = currentRating.replace(/[^0-9\.\-\s]+/g, '');

  let ratingCandidates = currentRating.split(/\s+/).filter(Boolean);

  let allNumbers = [];

  ratingCandidates.forEach((candidate: string) => {
    if (candidate.includes('-')) {
      let rangeNumbers = candidate.split('-').map((n: string) => parseFloat(n)).filter((n: number) => !isNaN(n));

      if (rangeNumbers.length > 0) {
        allNumbers.push(
          rangeNumbers.reduce((a: number, b: number) => a + b, 0) / rangeNumbers.length
        );
      }
    } else {
      let val = parseFloat(candidate);

      if (!isNaN(val)) {
        allNumbers.push(val);
      }
    }
  });

  if (allNumbers.length === 0) {
    return null;
  }

  let ratingValue = allNumbers.reduce((a: number, b: number) => a + b, 0) / allNumbers.length;
  let normalized = (ratingValue / denominatorValue) * 5;

  normalized = Math.round(normalized * 100) / 100;

  return String(normalized);
};

export const averageNormalizedRatings = (ratings: number[]): number => {
  const avg = ratings.reduce((sum: number, val: number) => sum + val, 0) / ratings.length;

  return Math.round(avg * 100) / 100;
};
