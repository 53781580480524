// plugins/vee-validate.js
import { configure, extend } from 'vee-validate';
import { required, min_value, max_value, email, max } from 'vee-validate/dist/rules';
import { formatCurrency, unformatCurrency } from '~/helpers/formatCurrency';

export default ({ app, i18n }) => {
  const locale = (i18n?.localeProperties?.iso || '').replace('_', '-');
  const options = { currency: app.$vsf.$magento.config.state.getCurrency() || i18n?.localeProperties?.defaultCurrency };

  const currency = (value) => formatCurrency(value, locale, options);

  configure({
    // Disable rule inheritance from input props
    useConstraintAttrs: false,
  });

  extend('required', {
    ...required,
    params: ['name', 'type'],
    validate: (value, params?: { name: string, type: string }) => {
      const isSignUpCheckbox = params && params.type === 'sign-up-checkbox';

      if (isSignUpCheckbox) {
        const error = i18n.t('You need to accept the {0} to sign up.', {
          0: params.name || 'checkbox',
        });

        return value === true || error;
      }

      return required.validate(value);
    },
    message: 'Please enter a {_field_}',
  });

  extend('required_terms', {
    validate: value => {
      return value === true;
    },
    message: 'Please accept the terms and conditions',
  });

  extend('email', {
    ...email,
    message: 'Please enter a valid {_field_}',
  });

  extend('is_existing_email', {
    validate: (value, params) => {
      return params[0] === 'false';
    },
    message: 'The email you entered is associated with an existing account. Please enter a different email address.',
  });

  extend('is_email_subscribed', {
    validate: (value, params) => {
      return params[0] === 'false';
    },
    message: 'This email address is already subscribed',
  });

  extend('min_value', {
    ...min_value,
    params: ['min'],
    message: 'Please enter a minimum {_field_} of {min}',
  });

  extend('max_value', {
    ...max_value,
    params: ['max'],
    message: 'Please enter a maximum {_field_} of {max}',
  });

  extend('min_price', {
    validate: (value, params: any) => {
      // Value is unformatted as the input value has a format mask
      const parsedValue = unformatCurrency(value, locale);
      const parsedMin = parseFloat(params.min);

      return parsedValue >= parsedMin;
    },
    params: ['min'],
    message: (fieldName, { min }) => `Please enter a minimum ${fieldName} of ${currency(min)}`,
  });

  extend('requiredSelect', {
    ...required,
    message: 'Please select a value',
  });

  extend('phoneNumber', {
    validate: (value, params) => {
      return params[0] === 'true';
    },
    message: 'Please enter a valid phone number',
  });

  extend('password', {
    validate: value => {
      let errors = [];

      if (value.length < 8 || value.length > 64) {
        errors.push('The password must be between 8 and 64 characters. <br>');
      }

      if (value.trim() !== value) {
        errors.push('The password must not begin or end with a whitespace character. <br>');
      }

      const hasLowerCase = /[a-z]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasDigit = /\d/.test(value);
      const hasSymbol = /[!@#$%^&*()_+]/.test(value);

      const hasInvalidCharacters = (value) => {
        const pattern = new RegExp('(^([0-9A-Za-z\\d@#$%^&*\\-_+=[\\]{}|\\\\:&#39;,?/`~"();! ]|(\\.(?!@)))+$)|(^$)');

        return !pattern.test(value);
      };

      if (hasInvalidCharacters(value)) {
        errors.push('An invalid character was provided. <br>');
      }

      // Has at least 3 valid characters
      const hasValidCharacters = [hasLowerCase, hasUpperCase, hasDigit, hasSymbol].filter(Boolean).length < 3;

      if (hasValidCharacters) {
        errors.push('The password must have at least 3 of the following: <br>' +
          '&nbsp;- a lowercase letter <br>' +
          '&nbsp;- an uppercase letter <br>' +
          '&nbsp;- a digit <br>' +
          '&nbsp;- a symbol');
      }

      return errors.length === 0 ? true : errors.join('');
    },
    message: 'Invalid password format'
  });

  extend('confirmPassword', {
    validate(value, params: { password: string }) {
      return value === params.password;
    },
    params: ['password'],
    message: 'Passwords do not match'
  });

  extend('alpha_num_max', {
    validate: (value, { length }) => {
      let errors = [];
      const alphaNum = /^[a-zA-Z0-9'‘’., -]+$/;
      const isAlphaNum = alphaNum.test(value);
      const isMaxLength = value.length <= length;

      if (!isAlphaNum && !isMaxLength) {
        errors.push(
          'Only alphanumeric characters are allowed. <br>' +
          `Please enter less or equal than ${length} characters.`
        )
      } else if (!isAlphaNum) {
        errors.push('Only alphanumeric characters are allowed.')
      } else if (!isMaxLength) {
        errors.push(`Please enter less or equal than ${length} characters.`)
      }

      return errors.length === 0 ? true : errors.join('');
    },
    params: ['length']
  });

  extend('max', {
    ...max,
    params: ['length'],
    message: 'Please enter less than or equal {length} characters to {_field_} field',
  });

  extend('postcode', {
    params: ['country'],
    validate(value, { country }: { country: string }) {
      const rules = {
        GB: {
          maxLength: 8,
          // eslint-disable-next-line max-len
          regex: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/
        },
        US: {
          maxLength: 8,
          regex: /^\d{5}(-\d{4})?$/
        },
        CA: {
          maxLength: 10,
          regex: /^[A-Za-z][0-9][A-Za-z][\s]?[0-9][A-Za-z][0-9]$/
        },
        default: {
          maxLength: 10,
          regex: /^[A-Za-z0-9#+\-\\() ]*$/
        }
      };

      const { maxLength, regex } = rules[country] || rules.default;

      return value.length <= maxLength && regex.test(value);
    },
    message: 'Please enter a valid postcode for the selected country.'
  });
};
