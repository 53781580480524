


























import { defineComponent, ref, useRouter, useContext } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'ActionItemSearch',
  components: {
    LazyHydrate,
    SvgImage,
    AlgoliaSearchAutocomplete: () => import('@vaimo-algolia/components/search/AlgoliaSearchAutocomplete.vue'),
  },
  props: {
    hasSearchBox: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const isOpen = ref(false);
    const { localeRoute } = useContext();
    const router = useRouter();

    const toggleModal = () => {
      if (props.hasSearchBox) {
        isOpen.value = !isOpen.value;
      } else {
        router.push(localeRoute({ name: 'algolia-search' }));
      }
    };

    const closeModal = () => {
      isOpen.value = false;
    };

    return {
      isOpen,
      toggleModal,
      closeModal,
    };
  },
});
