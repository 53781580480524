import { defineStore } from 'pinia';
import { computed, ref } from '@nuxtjs/composition-api';

export const useHybrisCartStore = defineStore('cart-hybris', () => {
  // State
  const hybrisCart = ref(null);

  // Getters
  const isGuestCart = computed(() => hybrisCart.value?.cartData?.user.uid === 'anonymous');
  const totalItems = computed(() => hybrisCart.value?.cartData?.totalItems || 0);
  const cartId = computed(() => hybrisCart.value?.cartData?.code);
  const totalQty = computed(() => hybrisCart.value?.cartData?.deliveryItemsQuantity || 0);

  const totals = computed(() => ({
    subtotal: hybrisCart.value?.cartData?.subTotal?.value || 0,
    total: hybrisCart.value?.cartData?.totalPriceWithTax?.value || 0,
  }));

  const partCaseFee = computed(
    () => hybrisCart.value?.cartData?.additionalCosts?.find(cost => cost.adcProductCode === '63000015')
  );

  const productsByGroup = computed(() => [
    {
        group_name: 'inbond',
        items: hybrisCart.value?.cartData?.inBondOrderGroups[0]?.entries || [],
    },
    {
        group_name: 'delivery',
        items: hybrisCart.value?.cartData?.deliveryOrderGroups[0]?.entries || [],
    },
    {
        group_name: 'events',
        items: hybrisCart.value?.cartData?.eventOrderGroups[0]?.entries || [],
    },
    {
        group_name: 'vouchers',
        items: hybrisCart.value?.cartData?.postableOrderGroups[0]?.entries || [],
    }
  ].filter(group => group.items.length > 0));

  const productPromotions = computed(() => {
    const promotions = {};

    hybrisCart.value?.cartData?.appliedProductPromotions?.forEach(promotion => {
      const orderEntryNumber = promotion.consumedEntries[0].orderEntryNumber;

      promotions[orderEntryNumber] = {
        orderEntryNumber: orderEntryNumber,
        adjustedUnitPrice: promotion.consumedEntries[0].adjustedUnitPrice,
        ...promotion,
      };
    });

    return promotions;
  });

  // Actions
  const setHybrisCart = (value) => hybrisCart.value = value;

  return {
    hybrisCart,
    isGuestCart,
    totalItems,
    totalQty,
    cartId,
    totals,
    partCaseFee,
    productsByGroup,
    productPromotions,
    setHybrisCart,
  };
});
