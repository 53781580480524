









import { defineComponent } from '@nuxtjs/composition-api';
import ActionItem from '@theme/components/atoms/ActionItem.vue';
import { storeToRefs } from 'pinia';
import { useHybrisCartStore } from '~/modules/checkout/stores/useHybrisCart';

export default defineComponent({
  name: 'ActionItemCart',
  components: {
    ActionItem,
  },
  setup() {
    const hybrisCartStore = useHybrisCartStore();
    const { totalQty } = storeToRefs(hybrisCartStore);

    return {
      totalQty,
    };
  },
});
